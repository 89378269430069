import { useEffect, useState } from 'react';

export default function useMousePosition() {
    const [mousePosition, setMousePosition] = useState({
        x: -100,
        y: -100,
    });

    useEffect(
        () => {
            const mouseMoveHandler = (event) => {
                const { clientX, clientY } = event;
                setMousePosition({ x: clientX, y: clientY });
            };
            document.addEventListener('mousemove', mouseMoveHandler);

            return () => {
                document.removeEventListener('mousemove', mouseMoveHandler);
            };
        },
        [],
    );

    return mousePosition;
}
