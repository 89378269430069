import styles from './styles.module.scss';

function Contacts() {
    return (
        <div className={styles.contacts}>
            <a href="tel:+4916093574177">
                +49 160 93574177
            </a>
            <br />
            <a href="mailto:info@architekturatelier-halle.de">
                info@architekturatelier-halle.de
            </a>
        </div>
    );
}

export default Contacts;
