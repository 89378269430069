import styles from './styles.module.scss';

function Contacts() {
    return (
        <div className={styles.jobTitle}>
            Freie Architektin
            <br />
            Diplom-Ingenieur
            <br />
            Feng Shui Architektin
        </div>
    );
}

export default Contacts;
