import styles           from './styles.module.scss';
import useMousePosition from '../../hooks/useMousePosition';

function Cursor() {
    const { x, y } = useMousePosition();

    return (
        <>
            <div
                style={{
                    left: `${x}px`,
                    top:  `${y}px`,
                }}
                className={styles.cursor}
            ></div>
        </>
    );
}

export default Cursor;
