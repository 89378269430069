import React                from 'react';
import ReactDOM             from 'react-dom/client';
import ComingSoon           from './screens/ComingSoon/ComingSoon';
import reportWebVitals      from './reportWebVitals';
import MouseContextProvider from './context/mouse-context';

import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <MouseContextProvider>
            <ComingSoon />
        </MouseContextProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
