import styles   from './styles.module.scss';
import Cursor   from '../../components/Cursor';
import Headline from '../../components/Headline';
import Address  from '../../components/Address';
import Contacts from '../../components/Contacts';
import JobTitle from '../../components/JobTitle';

function ComingSoon() {
    return (
        <>
            <Cursor />
            <div className={styles.comingSoonContainer}>
                <div>
                    <Headline />
                    <JobTitle />
                    <Address />
                    <Contacts />
                </div>
            </div>
        </>
    );
}

export default ComingSoon;
