import styles from './styles.module.scss';

function Address() {
    return (
        <div className={styles.address}>
            Talstraße 24
            <br />
            06120 Halle (Saale)
            <br />
        </div>
    );
}

export default Address;
