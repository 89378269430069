import './styles.module.scss';

function Headline() {
    return (
        <h1>
            <b>
                BEATRIX NOACK
            </b>
            <br />
            Architekturatelier
        </h1>
    );
}

export default Headline;
